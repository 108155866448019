export default {
    methods: {
        translateInterval(interval, type) {
            type = type || 'singular';

            if (type === 'plural') {
                switch (interval) {
                    case 'day':
                        return this.trans('days');
                    case 'week':
                        return this.trans('weeks');
                    case '2-weeks':
                        return this.trans('every 2 weeks');
                    case 'month':
                        return this.trans('months');
                    case 'quarter':
                        return this.trans('quarters');
                    case '6-month':
                        return this.trans('every 6 months');
                    case 'year':
                        return this.trans('years');
                }
            }

            switch (interval) {
                case 'day':
                    return this.trans('day');
                case 'week':
                    return this.trans('week');
                case '2-weeks':
                    return this.trans('2 weeks');
                case 'month':
                    return this.trans('month');
                case 'quarter':
                    return this.trans('quarter');
                case '6-month':
                    return this.trans('6 months');
                case 'year':
                    return this.trans('year');
            }
        }
    }
}